import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { qs, addClass, loaded, getParam, join } from "modules/modules"
import { addStalkerHoverEvent, removeStalkerHoverEvent, activateHoverCursor, deactivateHoverCursor } from "modules/mouseStalker"
import noimage from 'assets/img/common/noimage.jpg'
import axios from 'axios';
import PrimaryHeading from 'components/headings/PrimaryHeading'
import NavigationButton from 'components/ui/NavigationButton'
import { API_BASE_URL } from 'config/Constants';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";

type Props = {
}
export default function Recommendation(props: Props) {


  // 記事セット
  const limit = 9;
  const [articles, setArticleList] = useState([]);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/sticky_posts?limit=${limit}${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    const swiperBullets: string = "#recommendation_swiper_pagination span" //スライダーのページネーションバレット

    const uri = composeApiUrl();
    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        console.log(res);
        const composedData = composeArticles(res.data);
        setArticleList(composedData);
      })
      .catch(function (error) {
        addClass(qs(".recommendation.js__load_required"), "failed");
      })
      .finally(function () {
        addStalkerHoverEvent(swiperBullets);
      });
    return () => {
      removeStalkerHoverEvent(swiperBullets);
    }
  }, []);

  function composeArticles(data:any): any[] {
    const works = data?.works || [];
    const topics = data?.topics || [];
    const worksTopics = works.concat(topics)

    // worksTopicsを9件に絞る
    if (worksTopics.length > limit) {
      return worksTopics.slice(0, limit)
    } else {
      return worksTopics
    }
  }

  // サムネURLの設定
  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }


  const showArticles = useMemo(() => {
    let postTitle;
    let postUrl;
    let categories;
    // let imgSrc;
    if (articles?.length) {
      loaded(".recommendation.js__load_required");
      return (
        articles.map((article: any, index: number) => {
          postTitle = article.title; // タイトルを設定
          postUrl = "/works/" + article.id; // URLを設定
          categories = article.cat_names;
          return (
            <SwiperSlide key={index}>
              <Link to={postUrl} className="anchor" title={postTitle} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} >
                <div className="article_info">
                  <img src={img_src(article)} alt={postTitle} />
                  <div className="info">
                    <p className="categories">{join(categories, "・")}</p>
                    {/* <p className="categories">{cat_names}</p> */}
                    <p className="title">{postTitle}</p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          )
        })
      )
    }
  }, [articles])

  return (
    <>
      <div id="js__recommendation" className="recommendation js__load_required">
        {articles.length &&
          <>

            <PrimaryHeading letter="recommended" modifire="" />

            <div className="recommendation_slider_wrapper">

              <Swiper
                autoplay={{
                  delay: 1900,
                  disableOnInteraction: false,
                }}
                speed={900}
                dir="rtl" // 左右逆
                centeredSlides={false}
                modules={[Navigation, Autoplay, Pagination]}
                loop={true}
                slidesPerView={"auto"}
                draggable={true}
                navigation={{
                  nextEl: "#recommendation_swiper_next .c__navigation_button"
                }}
                pagination={{
                  clickable: true,
                  el: "#recommendation_swiper_pagination",
                  renderBullet: function (index: any, className: any) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                  },
                }}
                className="recommendation_swiper"
                breakpoints={{
                  0: {
                    spaceBetween: 10
                  },
                  530: {
                    spaceBetween: 20
                  },
                }}

              >
                {showArticles}
              </Swiper>
              <div id="recommendation_swiper_next">
                <NavigationButton />
              </div>
              <div id="recommendation_swiper_pagination"></div>
            </div>
          </>
        }
      </div>
    </>
  );
}
