import { motion } from "framer-motion";
import FirstView from './FirstView';
import Recommendation from './Recommendation';
import Works from './Works';
import { useEffect } from 'react';
import { ISOvserver, setSvh, setStaticSvh } from "modules/modules"
import useBackground from "hooks/useBackground"
import VirtualEstateButton from '../../pages/home/VirtualEstateButton';
import ServicesList from "./ServicesList";
import TopicsList from "./TopicsList";
type Props = {
}
export default function Index(props: Props) {
  const background = useBackground();

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();

    // 別ページから戻ってきた際にvhを再設定
    setSvh();
    setStaticSvh();

    background.activate()
    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >
        <main id="p__home">
          <FirstView />
          <Recommendation />

          {/* <div className="service_topics_wrapper l__content_width">
            <ServicesList />
            <TopicsList />
          </div> */}

          <div id="works">
            <Works />
            <VirtualEstateButton />
          </div>
        </main>
      </motion.div>
    </>
  );
}